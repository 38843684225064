/*---------------*\
  $ARTICLE
\*---------------*/
/**
 * INTRODUCTION...
 */



body.single-post {



  .main-content {



    /*---------------*\
      $INTRODUCTION
    \*---------------*/
    .introduction {
      color: $color-black;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 25px;
    }



  }



}
