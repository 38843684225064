/*---------------*\
  $FOOTER
\*---------------*/
/**
 * MAIN FOOTER...
 */



.main-footer {
  background-color: $color-dark-grey;
  padding: 27px 27px 0;

  .social-networks {
    text-align: center;

    h1, h2, h3, h4, h5, h6,
    .title {
      color: $color-white;
      font-family: $font-1;
      font-size: 30px;
      font-weight: 200;
      margin: 0 0 10px;
      text-transform: uppercase;
    }

    a,
    a:focus,
    a:hover {
      border: 2px solid $color-white;
      color: $color-white;
      display: inline-block;
      font-size: 2.125rem;
      height: 64px;
      line-height: 56px;
      margin: 0 5px 27px 0;
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: 64px;
      @include border-radius(50%);
      @include transition(all, ease, .1s);

      i {
        left: 50%;
        position: absolute;
        top: 50%;
        @include translate(-50%, -50%);
      }

      [class^="socicon-"]:before,
      [class*=" socicon-"]:before {
        vertical-align: top;
      }
    }

    a:hover {
      font-size: 2.5rem;

      &.facebook {
        border-color: #3a5ba2;
        color: #3a5ba2;
      }

      &.dailymotion {
        border-color: #0066dc;
        color: #0066dc;
      }

      &.google-plus {
        border-color: #e1584b;
        color: #e1584b;
      }

      &.instagram {
        border-color: #ee4d5e;
      }

      &.instagram i {
        background: linear-gradient(0deg, #BC32A4 0%, #E03768 50%, #F67C33 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &.linkedin {
        border-color: #2a66bc;
        color: #2a66bc;
      }

      &.pinterest {
        border-color: #b12324;
        color: #b12324;
      }

      &.rss {
        border-color: #e8ae21;
        color: #e8ae21;
      }

      &.snapchat {
        border-color: #ffff00;
        color: #ffff00;
      }

      &.spotify {
        border-color: #1db954;
        color: #1db954;
      }

      &.twitter {
        border-color: #55acee;
        color: #55acee;
      }

      &.vine {
        border-color: #00bf8f;
        color: #00bf8f;
      }

      &.youtube {
        border-color: #e62117;
        color: #e62117;
      }
    }
  }

  aside {
    background: -webkit-gradient(linear, left top, left bottom, from(#4d4d4d), to(#39393b));
    background: linear-gradient(#4d4d4d, #39393b);
    margin-left: -27px;
    margin-right: -27px;
    padding: 13px 27px;
    text-align: center;

    @media only screen and (min-width: 768px) {
      text-align: left;
    }

    .logo {
      position: relative;

      @media only screen and (min-width: 768px) {
        float: right;
        text-align: right;
      }

      a,
      a:focus,
      a:hover {
        color: $color-white;
        font-family: 'Malcolm France', sans-serif;
        font-size: 3.125rem;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include transition(all, ease, .3s);

        @media only screen and (min-width: 768px) {
          position: absolute;
          right: 0;
          top: -13px;
        }
      }

      a:hover {
        color: $color-yellow;
        -webkit-animation-duration: 100ms;
                animation-duration: 100ms;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-name: shake-little;
                animation-name: shake-little;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        -webkit-animation-play-state: running;
                animation-play-state: running;
        -webkit-transform-origin: center center;
                transform-origin: center center;
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        font-size: 0.75rem;
        line-height: 22px;

        + li:before {
          color: $color-grey-2;
          content: "|";
          display: inline-block;
          margin-right: 5px;
        }

        a,
        a:focus,
        a:hover {
          color: $color-grey-4;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }

        &.search {display: none;}
      }
    }

    p {
      color: $color-grey-4;
      font-size: 13.3px;
      line-height: 22px;
      margin: 0;

      > span {
        color: $color-grey-2;
      }
    }
  }
}
