// ../style.scss

// Fonts
$font-1          : 'Montserrat', sans-serif;
$font-2          : 'Roboto Condensed', sans-serif;

// Colors
$color-yellow    : #{'rgba(253, 200, 0, 1)'};
$color-red       : #{'rgba(92, 0, 0, 1)'};

// Black
$color-black     : #{'rgba(0, 0, 0, 1)'};

// White
$color-white     : #{'rgba(255, 255, 255, 1)'};

// Grey
$color-dark-grey : #{'rgba(50, 48, 47, 1)'};
$color-grey-1    : #{'rgba(65, 65, 65, 1)'};
$color-grey-2    : #{'rgba(81, 81, 81, 1)'};
$color-grey-3    : #{'rgba(142, 142, 142, 1)'};
$color-grey-4    : #{'rgba(208, 208, 208, 1)'};
$color-off-white : #{'rgba(242, 242, 242, 1)'};

// Opacity
$color-white-25  : #{'rgba(255, 255, 255, .25)'};
$color-black-25  : #{'rgba(0, 0, 0, .25)'};
$color-black-50  : #{'rgba(0, 0, 0, .5)'};
$color-black-75  : #{'rgba(0, 0, 0, .75)'};
$color-shadow    : #{'rgba(29, 30, 28, .12)'};