/*---------------*\
  $CHARACTER
\*---------------*/
/**
 * BIOGRAPHY.....
 * CONTACT.......
 * BEST OF.......
 * FILMOGRAPHY...
 * ACTUALITY...
 */



body.page-template-character,
body.page-template-pro {



  .main-content {



    /*---------------*\
      $BIOGRAPHY
    \*---------------*/
    .biography {
      margin-bottom: 30px;
    }



    /*---------------*\
      $CONTACT
    \*---------------*/
    .contact {
      margin-bottom: 30px;

      address {
        border: 5px solid $color-yellow;
        margin: 0 0 15px;
        padding: 15px;
        @include border-radius(10px);
      }

      .btn,
      .btn:focus,
      .btn:hover {
        height: 64px;
        line-height: 1;
        position: relative;
        width: 64px;
        @include border-radius(50%);

        i {
          font-size: 2.125rem;
          left: 50%;
          margin: 0;
          position: absolute;
          top: 50%;
          @include translate(-50%, -50%)
        }
      }

      .advice,
      .advice:focus,
      .advice:hover {
        background: none;
        border-bottom: 1px dashed $color-grey-1;
        color: $color-grey-1;
        cursor: help;
        font-size: 0.875rem;
        outline: none;
        text-decoration: none;
      }

      .advice:hover {
        border-color: $color-yellow;
        color: $color-yellow;
      }
    }



    /*---------------*\
      $BEST OF
    \*---------------*/
    .best-of {

      img {
        margin-bottom: 15px;
      }

      .title {
        font-family: $font-1;
        font-size: 1.125rem;
        margin: 0;
        text-transform: uppercase;
      }

      .subtitle {
        font-family: $font-1;
        font-size: 0.75rem;
        margin: 0 0 30px;
      }
    }



    /*---------------*\
      $FILMOGRAPHY
    \*---------------*/
    .filmography {

      .nav-tabs {
        margin: 0 0 15px;

        > li {
          background: none;
          padding: 0;
        }

        > li > a,
        > li > a:focus,
        > li > a:hover,
        > li.active > a,
        > li.active > a:focus,
        > li.active > a:hover {
          background: transparent;
          color: $color-grey-1;
          font-size: 0.875rem;
          font-weight: 400;
          text-decoration: none;
          text-transform: uppercase;
        }

        > li > a:hover,
        > li.active > a,
        > li.active > a:focus,
        > li.active > a:hover {
          background: $color-off-white;
        }
      }

      .btn-group {
        margin: 0 0 15px;

        .btn {
          font-size: 0.75rem;
          text-transform: uppercase;
        }
      }

      .table {
        margin: 0 0 30px;

        > thead {

          > tr {

            > th {
              color: $color-grey-1;
              font-size: 0.875rem;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
        }

        > tbody {

          > tr {

            > td {
              font-size: 0.75rem;

              &:first-child {
                color: $color-grey-3;
                white-space: nowrap;
                width: 76px;
              }

              a,
              a:focus,
              a:hover {
                background-image:      -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow));
                background-image:      linear-gradient(180deg, $color-yellow, $color-yellow);
                background-position: 0 1em;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                color: $color-grey-1;
                text-decoration: none;
                @include transition(all, ease, .1s);
              }

              a:hover {
                background-position: 0 0;
              }
            }
          }
        }
      }

      ul {
        margin: 0;
        padding: 0 0 0 15px;

        li {
          background: none;
          color: $color-grey-3;
          font-size: 0.75rem;
          line-height: 20px;
          padding: 0;
        }
      }

      .tooltip-inner {

        &::after {
          background-image: url("../img/icon/allocine.svg");
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: auto 100%;
          content: "";
          display: inline-block;
          height: 17px;
          margin: -1px 0 0 5px;
          vertical-align: middle;
          width: 17px;
        }
      }
    }



    /*---------------*\
      $ACTUALITY
    \*---------------*/
    .actuality {
      margin-bottom: 30px;

      article {
        margin-bottom: 5px;

        a,
        a:focus,
        a:hover {
          color: $color-grey-1;
          display: block;
          padding: 7px 15px;
          text-decoration: none;

          @media only screen and (min-width: 992px) {
            padding: 5px;
          }

          .img {
            display: block;
            float: left;
            margin-right: 15px;
            max-width: 90px;
            overflow: hidden;
            position: relative;

            @media only screen and (min-width: 992px) {
              float: none;
              margin-right: 0;
              max-width: inherit;
            }

            i {
              color: $color-white;
              font-size: 4.375rem;
              left: 50%;
              position: absolute;
              top: 50%;
              z-index: 1;
              @include opacity(.5, 50);
              @include translate(-50%, -50%);
            }

            img {
              display: block;
              @include transition(all, ease, .3s);
            }
          }
        }

        a:hover {
          background:         -webkit-gradient(linear, left top, right top, from(#e8e8e8), to(#d8d9d9));
          background:         linear-gradient(90deg, #e8e8e8, #d8d9d9);

          @media only screen and (min-width: 992px) {
            background:         -webkit-gradient(linear, left top, left bottom, from(#e8e8e8), to(#d8d9d9));
            background:         linear-gradient(#e8e8e8, #d8d9d9);
          }

          .img {

            img {
              -webkit-transform: scale(1.1) rotate(0.01deg);
                      transform: scale(1.1) rotate(0.01deg);
            }
          }
        }

        .post-title {
          display: block;
          font-family: $font-2;
          font-size: 1rem;
          font-weight: 500;
          line-height: 24px;
          margin: 0;
          padding: 10px 10px 5px;
        }
      }
    }



  }



}
