/*---------------*\
  $EPISODE
\*---------------*/
/**
 * RATING............
 * OWL CAROUSEL......
 * TECHNICAL SHEET...
 * SUMMARY...........
 * INTRIGUES.........
 * ANECDOTES.........
 * MUSIC.............
 * ERRORS............
 * NEXT EPISODE......
 */



body.page-template-episode {



  .main-content {



    /*---------------*\
      $RATING
    \*---------------*/
    .rating {
      margin-bottom: 15px;

      @media only screen and (min-width: 1200px) {
        padding-right: 30px;
      }

      @media only screen and (min-width: 768px) {
        text-align: right;
      }

      .title {
        border-right: 2px solid $color-grey-1;
        color: $color-grey-1;
        font-family: $font-1;
        font-size: 1.25rem;
        font-weight: 200;
        display: inline-block;
        height: 32px;
        line-height: 32px;
        margin: 0 9px 0 0;
        padding-right: 15px;
        text-transform: uppercase;
        vertical-align: middle;

        @media (min-width: 768px) and (max-width: 991px) {
          display: block;
          border: 0 none;
          margin: 0;
          padding: 0;
        }
      }

      .post-ratings {
        display: inline-block;
        font-size: 0;
        vertical-align: middle;
        width: auto;
      }
    }



    /*---------------*\
      $OWL CAROUSEL
    \*---------------*/
    .owl-container {
      margin: 0;
    }



    /*---------------*\
      $TECHNICAL SHEET
    \*---------------*/
    .technical-sheet {
      background-color: $color-dark-grey;
      color: $color-white;
      margin-bottom: 30px;
      padding: 15px;

      a,
      a:focus,
      a:hover {
        color: $color-white;
      }

      a:hover {
        color: $color-dark-grey;
      }
    }



    /*---------------*\
      $SUMMARY
    \*---------------*/
    .summary {
      margin-bottom: 30px;

      .label {
        background-color: $color-off-white;
        color: $color-grey-1;
        font-size: 0.875rem;
        font-weight: 400;
        height: 18px;
        line-height: 16px;
        padding: 1px 5px;
        position: relative;
        text-transform: uppercase;
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;
      }
    }



    /*---------------*\
      $INTRIGUES
    \*---------------*/
    .intrigues {
      margin-bottom: 30px;

      dl {

        dt {
          font-weight: 700;
          margin-top: 15px;

          a,
          a:focus,
          a:hover {
            background: none;
            color: $color-grey-1;
            text-decoration: none;
          }
        }

        dd {
          padding: 5px;
        }
      }

      .label {
        background-color: $color-off-white;
        color: $color-grey-1;
        font-size: 0.875rem;
        font-weight: 400;
        height: 18px;
        line-height: 16px;
        padding: 1px 5px 1px 26px;
        position: relative;
        text-transform: uppercase;
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;

        i {
          font-size: 1rem;
          height: 16px;
          left: 5px;
          position: absolute;
          top: 1px;
          width: 16px;
          @include transition(all, ease, .3s);
          &::before {
            content: "\f146";
          }
        }
      }
      .collapsed {

        .label {

          i {

            &::before {
              content: "\f0fe";
            }
          }
        }
      }
    }



    /*---------------*\
      $ANECDOTES
    \*---------------*/
    .anecdotes {
      margin-bottom: 30px;

      > ul {

        > li {
          background: url("../img/icon/list-style-arrow.png") no-repeat 0 5px;
          margin-bottom: 15px;
          padding-left: 17px;
        }
      }
    }



    /*---------------*\
      $MUSIC
    \*---------------*/
    .music {
      margin-bottom: 30px;

      > ul {

        > li {

          > ul {

            > li {
              background: none;
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }

      .label {
        background-color: $color-off-white;
        color: $color-grey-1;
        font-size: 0.875rem;
        font-weight: 400;
        height: 18px;
        line-height: 16px;
        padding: 1px 5px;
        position: relative;
        text-transform: uppercase;
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;
      }

      .player {

        @media only screen and (max-width: 767px){
          margin-top: 15px;
        }

        @media only screen and (min-width: 768px){
          text-align: right;
        }
      }
    }



    /*---------------*\
      $ERRORS
    \*---------------*/
    .errors {
      margin-bottom: 30px;

      .label {
        background-color: $color-off-white;
        color: $color-grey-1;
        font-size: 0.875rem;
        font-weight: 400;
        height: 18px;
        line-height: 16px;
        padding: 1px 5px;
        position: relative;
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;
      }
    }



    /*---------------*\
      $NEXT EPISODE
    \*---------------*/
    .next-episode {

      a,
      a:focus,
      a:hover {

        background-color: $color-black;
        display: block;
        margin-bottom: 30px;
        overflow: hidden;
        text-align: center;
        text-decoration: none;

        .title {
          color: $color-white;
          display: none;
          left: 0;
          position: absolute;
          text-align: center;
          top: 50%;
          width: 100%;
          z-index: 2;
          @include translate(0, -50%);

          @media only screen and (max-width: 479px){
            font-size: 0.75rem;
          }
        }

        .img {
          display: block;
          overflow: hidden;
          position: relative;
        }

        img {
          display: block;
          width: 100%;
          -webkit-transition: all 0.3s;
                  transition: all 0.3s;
        }

        .btn {
          background-color: $color-grey-1;
          border-radius: 0;
          color: $color-yellow;
          display: block;
          font-size: 0.75rem;
          line-height: 19px;
          padding: 3px 13px;
          position: relative;
          text-transform: uppercase;
          z-index: 1;
          -webkit-transition: all 0.3s;
                  transition: all 0.3s;

          @media only screen and (max-width: 479px){
            font-size: 0.5625rem;
          }

          .arrow-left,
          .arrow-right {
            background: no-repeat 0 -11px;
            content: "";
            display: inline-block;
            height: 11px;
            line-height: 1;
            margin-top: 2px;
            position: relative;
            vertical-align: text-top;
            width: 9px;
          }

          .arrow-left {
            background-image: url("../img/icon/sprite-arrow-left.png");
            margin-right: 5px;
          }

          .arrow-right {
            background-image: url("../img/icon/sprite-arrow-right.png");
            margin-left: 5px;
          }
        }
      }

      a:hover {

        .title {
          display: block;
        }

        img {
          @include opacity(.5, 50);
          -webkit-transform: scale(1.1);
              -ms-transform: scale(1.1);
                  transform: scale(1.1);
        }

        .btn {
          background: $color-yellow;
          color: $color-grey-1;

          .arrow-left,
          .arrow-right {
            background-position: 0 0;
          }

          .arrow-left {
            animation: animArrowLeft .3s linear;
          }

          .arrow-right {
            animation: animArrowRight .3s linear;
          }
        }
      }
    }



  }



}