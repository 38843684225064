/*---------------*\
  $BUTTONS
\*---------------*/
/**
 * DEFAULT......
 * BTN YELLOW...
 */



a,
a:focus,
a:hover,
button,
button:focus,
button:hover {
  outline: none !important;
}

.btn-yellow,
.btn-yellow:focus,
.btn-yellow:hover {
  background: $color-grey-1 !important;
  border: 1px solid $color-grey-1 !important;
  color: $color-yellow !important;
  display: inline-block !important;
  font-family: $font-2;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 19px;
  padding: 3px 13px !important;
  text-decoration: none;
  text-transform: uppercase;
  @include border-radius(0);
  @include transition(all, ease, .3s);

  i {
    font-size: 1rem;
    margin-top: -2px;
    margin-right: 5px;
    vertical-align: middle;
  }
}

.btn-yellow:hover {
  background: $color-yellow !important;
  border-color: $color-yellow !important;
  color: $color-grey-1 !important;
}
