/*---------------*\
  $CAROUSEL
\*---------------*/
/**
 * OWL CONTAINER...
 * OWL CAROUSEL....
 */



.owl-container {
  background-color: $color-dark-grey;
  margin: 0 0 30px;
  position: relative;

  &.fullscreen {
    bottom: 0;
    left: 0;
    margin: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;

    img {
      width: auto;
    }
  }

  .expand,
  .expand:focus,
  .expand:hover {
    background-color: $color-black-25;
    border: 0 none;
    color: $color-grey-3;
    font-size: 1.25rem;
    line-height: 1;
    padding: 5px;
    position: absolute;
    outline: none;
    right: 20px;
    top: 20px;
    z-index: 1;
    filter: alpha(opacity=0);
    @include opacity(0, 0);
    @include transition(all, ease, .3s);

    @media only screen and (max-width: 991px) {
      display: block;
      @include opacity(1, 100);
    }
  }

  .expand:hover {
    color: $color-white;
  }

  &:hover .expand,
  &:hover .expand:focus,
  &:hover .expand:hover {
    @include opacity(1, 100);
  }

  a,
  a:focus,
  a:hover {
    color: $color-white !important;
  }
}

.owl-carousel {
  color: $color-white;
  position: relative;

  .owl-item {

    .item {

      .title {
        color: $color-white;
        margin: 0 0 15px;
      }

      .description {
        padding-top: 15px;
      }
    }
  }

  .owl-controls {
    line-height: 1;
    margin: 0;

    .owl-page {

      span {
        border-radius: 50%;
        height: 15px;
        margin: 0 5px;
        width: 15px;
      }
    }

    .owl-buttons {

      .owl-prev,
      .owl-next {
        background: no-repeat 50% 50% $color-dark-grey;
        bottom: 15px;
        margin: 0;
        position: absolute;
        text-indent: -9999px;
        top: 15px;
        width: 15px;
        @include border-radius(0);
      }

      .owl-prev {
        background-image: url("../img/icon/arrow-left.png");
        left: 0;
      }

      .owl-next {
        background-image: url("../img/icon/arrow-right.png");
        right: 0;
      }
    }
  }

  &#mainCarousel {

    .owl-item {
      overflow: hidden;

      &.active {

        .item {

          .content {
            @include opacity(1, 100);
            -webkit-animation-name: fadeInUp;
                    animation-name: fadeInUp;
            -webkit-animation-duration: 1s;
                    animation-duration: 1s;
            -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;
          }
        }
      }

      .item {
        background: no-repeat 50% 50% $color-off-white;
        background-size: cover;
        color: $color-grey-1;
        height: 0;
        position: relative;

        @media only screen and (max-width: 767px) {
          padding-bottom: 75%;
        }

        @media only screen and (min-width: 768px) {
          padding-bottom: 41.841%;
        }

        .content {
          bottom: 15px;
          cursor: default;
          left: 15px;
          line-height: 1;
          padding: 0 15px;
          position: absolute;
          right: 15px;
          text-align: center;
          z-index: 1;
          @include opacity(0, 0);
          -webkit-animation-name: fadeInDown;
                  animation-name: fadeInDown;
          -webkit-animation-duration: .5s;
                  animation-duration: .5s;
          -webkit-animation-fill-mode: both;
                  animation-fill-mode: both;

          @media only screen and (min-width: 768px) {
            bottom: 15%;
            padding: 0;
          }

          .title {
            background-color: $color-black-75;
            color: $color-white;
            display: inline-block;
            font-family: $font-2;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            margin: 0;
            max-width: 810px;
            padding: 5px 8px;
            text-transform: uppercase;

            @media only screen and (min-width: 768px) and (max-width: 991px) {
              font-size: 2.5rem;
            }

            @media only screen and (min-width: 992px) {
              font-size: 3rem;
            }
          }

          .subtitle {
            color: $color-grey-1;
            font-family: $font-2;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1rem;
            margin: 0;
            text-transform: uppercase;

            @media only screen and (min-width: 768px) {
              font-size: 1rem;
              line-height: 1.25rem;
            }

            span {
              background-color: $color-white;
              display: inline;
              padding: 0px 8px;
              vertical-align: baseline;
            }
          }
        }
        
        a {
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          text-decoration: none;
          top: 0;
        }
      }
    }

    .owl-controls {
      padding-top: 15px;

      .owl-page {
        vertical-align: bottom;
      }

      .owl-buttons {

        .owl-prev,
        .owl-next {
          background-color: transparent;
          bottom: 46px;
          padding: 30px;
        }

        .owl-prev {
          left: 15px;
        }

        .owl-next {
          right: 15px;
        }
      }
    }
  }

  &#shopCarousel {
    margin-right: -15px;
    margin-left: -15px;
    width: auto;

    .owl-item {

      .item {
        padding: 0 30px;
        text-align: center;

        a,
        a:focus,
        a:hover {
          display: block;
          text-decoration: none;
        }

        a:hover {

          .btn-yellow {
            background: $color-yellow !important;
            border-color: $color-yellow !important;
            color: $color-grey-1 !important;
          }
        }

        img {
          margin-bottom: 15px;
        }

        .title {
          color: $color-grey-1;
          display: block;
          font-family: $font-2;
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1;
          margin: 0 0 15px;

          @media only screen and (min-width: 992px) {
            font-size: 1.75rem;
          }
        }

        .price {
          color: $color-grey-1;
          display: block;
          margin-bottom: 15px;
          text-align: center;

          span {
            display: inline-block;
            font-family: $font-2;
            font-size: 2.5rem;
            font-weight: 500;
            line-height: 1;

            > span {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    .owl-controls {
      padding-top: 15px;

      .owl-page {
        vertical-align: bottom;
      }

      .owl-buttons {

        .owl-prev,
        .owl-next {
          background-color: transparent;
          bottom: 0;
          top: 0;
        }

        .owl-prev {
          background-image: url("../img/icon/arrow-left-grey.png");
        }

        .owl-next {
          background-image: url("../img/icon/arrow-right-grey.png");
        }
      }
    }
  }

  &#slideshow {

    .owl-item {

      .item {
        padding: 15px;
      }
    }
  }

  &#thumbnail {
    padding: 10px 15px;

    .owl-item {

      .item {
        cursor: pointer;
        margin: 5px;

        img {
          border: 3px solid transparent;
          @include opacity(.5, 50);

          &:hover {
            @include opacity(1, 100);
          }
        }
      }

      &.synced {

        .item {

          img {
            border-color: $color-yellow;
            @include opacity(1, 100);
          }
        }
      }
    }
  }
}
