/*---------------*\
  $POPIN
\*---------------*/
.modal {
  top: 10%;
}

.modal-header {

  .close {
    color: $color-white;
    text-shadow: 0 1px 0 $color-black;
  }
}

.modal-content {
  background-color: $color-black-50;
  color: $color-white;
  @include border-radius(0);
}

.modal-backdrop.in {
  @include opacity(.75, 75);
}
