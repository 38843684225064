/*---------------*\
  $ADMIN
\*---------------*/
/**
 * INTRODUCTION...
 */



body.login {
  background-position: center center;
  background-size: cover;
  position: relative;

  @media only screen and (max-width: 767px) and (orientation : portrait) {
    background-image: url('../img/bkg/admin-mobile.jpg');
  }

  @media only screen and (min-width: 768px) {
    background-image: url('../img/bkg/admin.jpg');
  }

  &:before {
    background-image: url("../img/bkg/pattern-stripe.png");
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  #login {
    position: relative;
    z-index: 2;
  }

  h1 {
    text-align: center;

    a {
      background: none;
      display: block;
      font-size: 0;
      height: auto;
      width: auto;

      &:before {
        // color: $color-yellow;
        color: $color-white;
        content: "M";
        display: block;
        font-family: Malcolm France,sans-serif;
        font-size: 5.1875rem;
        line-height: 1;
        text-indent: 0;
      }
    }
  }

  #backtoblog,
  #nav {

    a,
    a:focus,
    a:hover {
      border-bottom: 1px dashed $color-white;
      color: $color-white;
      font-size: 0.875rem;
      outline: none;
      text-decoration: none;
      text-shadow: 0 0 10px $color-black-75;
    }

    a:hover {
      border-color: $color-yellow;
      color: $color-yellow;
    }
  }

}
