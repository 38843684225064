/*---------------*\
  $HOME
\*---------------*/
/**
 * DEFAULT...........
 * NEWS..............
 * QUOTES............
 * GIFS..............
 * BROADCASTS........
 * TV-AREA...........
 * GRID..............
 * SOCIAL NETWORKS...
 * SHOP..............
 */



 body.home,
 body.page-template-home-dev {



  /*---------------*\
    $DEFAULT
  \*---------------*/

  .main-content {
    width: 100%;

    .title-highlight {
      margin-bottom: 30px;
    }

    .carousel-container {

      @media only screen and (max-width: 991px) {
        margin: 0 -15px 70px;
      }

      @media only screen and (min-width: 992px) {
        margin: 0 -30px 70px;
      }
    }

    ul.parallax {
      bottom: 0;
      height: 100%;
      left: 0;
      list-style: none;
      margin: 0;
      padding: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;

      li {
        height: 100%;
        position: absolute;
        width: 100%;

        img {
          margin-left: -5%;

          @media only screen and (max-width: 767px) {
            height: 100%;
            max-width: inherit;
            width: auto;
          }

          @media only screen and (min-width: 768px) {
            max-width: inherit;
            width: 110%;
          }
        }
      }
    }



    /*---------------*\
      $NEWS
    \*---------------*/
    .news {
      margin-bottom: 100px;

      article {

        @media only screen and (max-width: 767px) {
          border-bottom: 1px solid $color-off-white;
          margin-bottom: 30px;
          padding-bottom: 25px;
        }

        @media only screen and (min-width: 768px) {
          margin-bottom: 20px;
        }

        &.last {

          @media only screen and (min-width: 992px) {
            text-align: right;
          }
        }
      }

      .horizontal,
      .horizontal:focus,
      .horizontal:hover {
        color: $color-grey-1;
        display: block;
        margin-right: -5px;
        margin-left: -5px;
        padding: 5px;
        text-decoration: none;
        @include transition(all, ease, .3s);

        @media only screen and (min-width: 992px) {
          margin: 0;
          padding: 0;
        }

        .article-container {
          display: block;

          @media only screen and (min-width: 992px) {
            display: table;
            width: 100%;
          }
        }

        .col {
          display: block;

          @media only screen and (min-width: 992px) {
            display: table-cell;
            padding: 0 20px;
            vertical-align: middle;
            width: 50%;
          }
        }

        .post-meta {
          color: $color-grey-3;
          display: block;
          font-size: 0.6875rem;
          margin-bottom: 5px;
          text-transform: uppercase;

          .date,
          .hour {
            display: inline-block;
          }
        }

        .category {
          color: $color-white;
          display: block;
          font-family: $font-2;
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 5px;
          text-align: center;
          text-transform: uppercase;

          @media only screen and (min-width: 992px) {
            font-size: 1rem;
          }

          span {
            background-color: $color-yellow;
            display: inline;
            padding: 0px 8px;
            vertical-align: baseline;
          }
        }

        .post-title {
          display: block;
          font-family: $font-2;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 15px;

          @media only screen and (min-width: 992px) {
            font-size: 1.75rem;
          }
        }

        .post-desc {
          color: $color-grey-3;
          display: block;
          font-size: 0.9375rem;
          line-height: 22px;
          margin-bottom: 15px;
        }

        .img-container {
          display: block;
          margin-bottom: 15px;
          overflow: hidden;
          padding: 0;
          position: relative;

          @media only screen and (min-width: 992px) {
            margin-bottom: 0;
          }

          .img {
            display: block;
            height: 0;
            padding-bottom: 45.75%;
            position: relative;
            @include transition(all, ease, .3s);

            img {
              height: auto;
              left: 50%;
              position: absolute;
              top: 50%;
              width: 100%;
              @include translate(-50%, -50%);
            }
          }

          i {
            color: $color-white;
            font-size: 4.375rem;
            left: 50%;
            position: absolute;
            top: 50%;
            z-index: 1;
            @include opacity(.5, 50);
            @include translate(-50%, -50%);
          }
        }
      }

      .horizontal:hover {
        background: -webkit-gradient(linear, left top, right top, from(#f9f9f9), to(#f2f2f2));
        background: linear-gradient(90deg, #f9f9f9, #f2f2f2);

        .img-container {

          .img {
            -webkit-transform: scale(1.1);
                    transform: scale(1.1);
          }
        }
      }

      .vertical,
      .vertical:focus,
      .vertical:hover {
        color: $color-grey-1;
        display: block;
        margin-right: -5px;
        margin-left: -5px;
        padding: 5px;
        position: relative;
        text-decoration: none;
        @include transition(all, ease, .3s);

        .img-container {
          display: block;
          margin-bottom: 15px;
          overflow: hidden;
          position: relative;

          .img {
            display: block;
            height: 0;
            padding-bottom: 45.75%;
            position: relative;
            @include transition(all, ease, .3s);

            @media only screen and (min-width: 992px){
              padding-bottom: 100%;
            }

            img {
              left: 50%;
              max-width: inherit;
              position: absolute;
              top: 50%;
              @include translate(-50%, -50%);

              @media only screen and (max-width: 991px){
                height: auto;
                width: 100%;
              }

              @media only screen and (min-width: 992px){
                height: 100%;
                width: auto;
              }
            }
          }

          i {
            color: $color-white;
            font-size: 4.375rem;
            left: 50%;
            position: absolute;
            top: 50%;
            z-index: 1;
            @include opacity(.5, 50);
            @include translate(-50%, -50%);
          }
        }

        .category {
          color: $color-white;
          display: block;
          font-family: $font-2;
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 5px;
          text-align: center;
          text-transform: uppercase;

          @media only screen and (min-width: 992px) {
            font-size: 1rem;
          }

          span {
            background-color: $color-yellow;
            display: inline;
            padding: 0px 8px;
            vertical-align: baseline;
          }
        }

        .post-title {
          display: block;
          font-family: $font-2;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 15px;

          @media only screen and (min-width: 992px) {
            font-size: 1.5rem;
          }
        }

        .post-desc {
          color: $color-grey-3;
          display: block;
          font-size: 0.9375rem;
          line-height: 22px;
          margin-bottom: 15px;
        }

        .post-meta {
          color: $color-grey-3;
          display: block;
          font-size: 0.6875rem;
          margin-bottom: 0;
          text-transform: uppercase;

          .date,
          .hour,
          .comments {
            display: inline-block;
            margin-bottom: 0;
          }
        }
      }

      .vertical:hover {
        background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#f2f2f2));
        background: linear-gradient(#f9f9f9, #f2f2f2);

        .img-container {

          .img {
            -webkit-transform: scale(1.1);
                    transform: scale(1.1);
          }
        }
      }

    }



    /*---------------*\
      $QUOTES
    \*---------------*/
    .quotes {
      height: 100%;

      .title-highlight {
        margin-top: 15px;
      }
    }



    /*---------------*\
      $GIFS
    \*---------------*/
    .gifs {
      height: 100%;

      .title-highlight {
        margin-top: 15px;
      }
    }



    /*---------------*\
      $BROADCASTS
    \*---------------*/
    .broadcasts {
      background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#f2f2f2));
      background: linear-gradient(#f9f9f9, #f2f2f2);
      height: 100%;
      padding: 0 15px;

      @media only screen and (max-width: 991px) {
        margin-top: 100px;
      }

      .title-highlight {
        margin-top: 15px;
      }

      .subtitle {
        color: $color-grey-1;
        font-family: $font-2;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 15px;
        text-align: center;
        text-transform: uppercase;

        @media only screen and (min-width: 992px) {
          font-size: 1.5rem;
        }
      }

      .btn {
        margin-bottom: 15px;
      }
    }



    /*---------------*\
      $TV-AREA
    \*---------------*/
    .tv-area {
      background-color: $color-dark-grey;

      @media only screen and (max-width: 767px) {
        margin: 100px -15px;
      }

      @media only screen and (min-width: 768px) {
        margin: 100px 0;
      }

      .col-left {
        padding-top: 15px;
        padding-bottom: 15px;

        .title {
          color: $color-white;
          font-family: $font-2;
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 1;
          margin: 25px 0;
          text-align: center;
          text-transform: uppercase;

          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            font-size: 1.75rem;
          }

          span {
            display: inline-block;
            padding: 0 30px;
            position: relative;
            vertical-align: middle;
            z-index: 2;

            @media only screen and (min-width: 768px) {
              padding: 0 85px;
            }

            &::before,
            &::after {
              border-top: 1px solid $color-white;
              border-bottom: 1px solid $color-white;
              content: "";
              height: 4px;
              position: absolute;
              top: 50%;
              width: 20px;
              z-index: 1;
              @include translate(0, -50%);

              @media only screen and (min-width: 768px) {
                width: 80px;
              }
            }

            &::before {
              left: 0;
            }

            &::after {
              right: 0;
            }
          }
        }

        article {

          a,
          a:focus,
          a:hover {
            color: $color-white;
            display: table;
            font-family: $font-2;
            font-weight: 500;
            margin-bottom: 15px;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            @include transition(all, ease, .3s);

            .img {
              display: table-cell;
              overflow: hidden;
              position: relative;
              vertical-align: middle;
              width: 50%;

              img {
                @include transition(all, ease, .3s);
              }

              i {
                font-size: 4.375rem;
                left: 50%;
                position: absolute;
                top: 50%;
                z-index: 1;
                @include opacity(.5, 50);
                @include translate(-50%, -50%);
              }
            }

            .content {
              display: table-cell;
              padding-left: 15px;
              text-align: center;
              vertical-align: middle;
              width: 50%;

              @media only screen and (min-width: 992px) and (max-width: 1199px) {
                padding-left: 5px;
              }

              .category {
                color: $color-yellow;
                display: block;
                font-size: 0.875rem;
                line-height: 1;
                margin-bottom: 10px;

                @media only screen and (min-width: 992px) {
                  font-size: 1rem;
                }

                span {
                  // background-color: $color-yellow;
                  // display: inline;
                  // padding: 0px 8px;
                  // vertical-align: baseline;
                }
              }

              .title {
                display: block;
                font-size: 1.25rem;
                line-height: 1;
                margin: 0;
              }
            }
          }

          a:hover {
            background-color: $color-grey-1;

            .img {

              img {
                -webkit-transform: scale(1.1);
                        transform: scale(1.1);
              }
            }
          }
        }
      }

      .col-right {

        @media only screen and (min-width: 992px) {
          float: right;
        }

        a,
        a:focus,
        a:hover {
          color: $color-white;
          display: table;
          font-family: $font-2;
          font-weight: 500;
          text-decoration: none;
          text-transform: uppercase;
          width: 100%;

          .img-container {
            display: block;
            overflow: hidden;
            padding: 0;
            position: relative;

            &:after {
              background: linear-gradient(rgba(0,0,0,.25),rgba(0,0,0,.75));
              bottom: 0;
              content: "";
              display: block;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1;
              @include opacity(.5, 50);
            }

            i {
              font-size: 8.75rem;
              left: 50%;
              position: absolute;
              top: 50%;
              z-index: 2;
              @include opacity(.5, 50);
              @include translate(-50%, -50%);
            }

            .img {
              display: block;
              height: 0;
              padding-bottom: 100%;
              position: relative;
              @include transition(all, ease, .3s);

              img {
                height: 100%;
                left: 50%;
                max-width: inherit;
                position: absolute;
                top: 50%;
                width: auto;
                @include translate(-50%, -50%);
              }
            }

            .content {
              bottom: 15px;
              left: 0;
              padding: 0 15px;
              position: absolute;
              right: 0;
              text-align: center;
              z-index: 2;

              @media only screen and (min-width: 992px) and (max-width: 1199px) {
                padding: 0 5px;
              }

              .category {
                color: $color-yellow;
                display: block;
                font-size: 0.875rem;;
                line-height: 1;
                margin-bottom: 5px;

                @media only screen and (min-width: 992px) {
                  font-size: 1rem;
                }

                span {
                  // background-color: $color-yellow;
                  // display: inline;
                  // padding: 0px 8px;
                  // vertical-align: baseline;
                }
              }

              .title {
                display: block;
                font-size: 1.875rem;
                font-weight: 700;
                line-height: 1;

                @media only screen and (min-width: 992px) {
                  font-size: 2.75rem;
                }
              }
            }
          }
        }

        a:hover {

          .img-container {

            .img {
              -webkit-transform: scale(1.1);
                      transform: scale(1.1);
            }
          }
        }
      }
    }



    /*---------------*\
      $OTHER
    \*---------------*/
    .other {
      margin-bottom: 70px;
    }



    /*---------------*\
      $GRID
    \*---------------*/
    .grid,
    .grid:focus,
    .grid:hover {
      color: $color-white;
      display: block;
      margin-bottom: 30px;
      overflow: hidden;
      position: relative;
      text-decoration: none;

      &.grid-large {

        .img-container {

          .img {

            @media only screen and (min-width: 768px) and (max-width: 991px) {
              padding-bottom: 45.25%;
            }

            @media only screen and (min-width: 992px) {
              padding-bottom: 47.15%;
            }

            img {

              @media only screen and (min-width: 768px) {
                height: auto;
                width: 100%;
              }
            }
          }
        }
      }

      .content {
        align-content: center;
        background:         -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .25)), to(rgba(0, 0, 0, .75)));
        background:         linear-gradient(rgba(0, 0, 0, .25), rgba(0, 0, 0, .75));
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: start;
        left: 0;
        padding: 5px 15px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 100%;
        z-index: 1;
        @include transition(all, ease, .3s);

        @media only screen and (max-width: 991px) {
          justify-content: center;
          top: 0;
        }

        .category {
          color: $color-white;
          display: block;
          font-family: $font-2;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1;
          margin: 5px 0 10px;
          text-transform: uppercase;

          @media only screen and (min-width: 1200px) {
            font-size: 1rem;
          }

          span {
            background-color: $color-yellow;
            display: inline;
            padding: 0px 8px;
            vertical-align: baseline;
          }
        }

        .title {
          display: block;
          font-family: $font-2;
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 15px;
          text-transform: uppercase;
          @include transition(all, ease, .3s);

          @media only screen and (min-width: 1200px) {
            font-size: 1.75rem;
          }
        }

        .desc {
          display: block;
          font-size: 1rem;
          line-height: 1;

          @media only screen and (min-width: 768px) and (max-width: 991px) {
            display: none;
          }

          @media only screen and (min-width: 992px) {
            @include opacity(0, 0);
            @include transition(all, ease, .3s);
          }
        }
      }

      .img-container {
        display: block;
        overflow: hidden;
        padding: 0;
        position: relative;

        .img {
          display: block;
          height: 0;
          position: relative;
          @include transition(all, ease, .3s);

          @media only screen and (max-width: 767px) {
            padding-bottom: 45.75%;
          }

          @media only screen and (min-width: 768px) {
            padding-bottom: 100%;
          }

          img {
            left: 50%;
            position: absolute;
            top: 50%;
            @include translate(-50%, -50%);

            @media only screen and (max-width: 767px) {
              height: auto;
              width: 100%;
            }

            @media only screen and (min-width: 768px) {
              height: 100%;
              max-width: inherit;
              width: auto;
            }
          }
        }
      }
    }

    .grid:hover {

      .content {
        justify-content: center;
        top: 0;

        .desc {
          @include opacity(1, 100);
        }
      }

      .img-container {

        .img {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
      }
    }



    /*---------------*\
      $SOCIAL NETWORKS
    \*---------------*/
    .social-networks {
      font-size: 0;
      margin-bottom: 30px;

      @media only screen and (max-width: 991px) {
        display: none;
      }

      .title-highlight {

        a,
        a:focus,
        a:hover {
          color: $color-yellow;
          @include transition(all, ease, .3s);
        }

        &.facebook {
          color: #3a5ba2;
          font-size: 3.125rem;
        }

        &.twitter {
          color: #55acee;
          font-size: 3.125rem;
        }
      }

      .btn-yellow,
      .btn-yellow:focus,
      .btn-yellow:hover {
        line-height: 33px;
        margin-top: 15px;
      }
    }



    /*---------------*\
      $SHOP
    \*---------------*/
    .shop {
      margin-bottom: 30px;

      .title-highlight {

        a,
        a:focus,
        a:hover {
          color: $color-yellow;
          @include transition(all, ease, .3s);
        }

        &.shop {
          font-size: 3.125rem;
        }
      }

      .owl-container {
        background-color: $color-white;

        .owl-carousel {

          &#slideshow {

            .owl-item {

              .item {
                padding: 0;
              }
            }
          }

          .owl-controls {

            .owl-buttons {

              .owl-prev,
              .owl-next {
                background-color: transparent;
              }

              .owl-prev {
                background-image: url("../img/icon/arrow-left-grey.png");
              }

              .owl-next {
                background-image: url("../img/icon/arrow-right-grey.png");
              }
            }
          }
        }
      }

      .btn-yellow,
      .btn-yellow:focus,
      .btn-yellow:hover {
        line-height: 33px;
      }
    }



  }



}
