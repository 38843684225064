/*---------------*\
  $ANIMATIONS
\*---------------*/
/**
 * animSlideUp.......
 * animArrowRight....
 * animArrowLeft.....
 * shakeLittle.......
 * fadeInUp..........
 * fadeInDown........
 */



.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes animSlideUp {
  0% {
    bottom: -153px;
  }
  100% {
    bottom: 0;
  }
}

@keyframes animSlideUp {
  0% {
    bottom: -153px;
  }
  100% {
    bottom: 0;
  }
}

.animSlideUp {
  -webkit-animation-name: animSlideUp;
          animation-name: animSlideUp;
}

@-webkit-keyframes animArrowRight {
  0% {
    left: 0;
    transform: translateX(0);
  }
  25% {
    @include opacity(0, 0);
    transform: translateX(10px);
  }
  75% {
    @include opacity(0, 0);
    transform: translateX(-10px);
  }
  100% {
    @include opacity(1, 100);
    transform: translateX(0);
  }
}

@keyframes animArrowRight {
  0% {
    left: 0;
    transform: translateX(0);
  }
  25% {
    @include opacity(0, 0);
    transform: translateX(10px);
  }
  75% {
    @include opacity(0, 0);
    transform: translateX(-10px);
  }
  100% {
    @include opacity(1, 100);
    transform: translateX(0);
  }
}

.animArrowRight {
  -webkit-animation-name: animArrowRight;
          animation-name: animArrowRight;
}

@-webkit-keyframes animArrowLeft {
  0% {
    left: 0;
    transform: translateX(0);
  }
  25% {
    @include opacity(0, 0);
    transform: translateX(-10px);
  }
  75% {
    @include opacity(0, 0);
    transform: translateX(10px);
  }
  100% {
    @include opacity(1, 100);
    transform: translateX(0);
  }
}

@keyframes animArrowLeft {
  0% {
    left: 0;
    transform: translateX(0);
  }
  25% {
    @include opacity(0, 0);
    transform: translateX(-10px);
  }
  75% {
    @include opacity(0, 0);
    transform: translateX(10px);
  }
  100% {
    @include opacity(1, 100);
    transform: translateX(0);
  }
}

.animArrowLeft {
  -webkit-animation-name: animArrowLeft;
          animation-name: animArrowLeft;
}

@-webkit-keyframes shake-little {
  2% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  4% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  6% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  8% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  10% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  12% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  14% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  16% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  18% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  20% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  22% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  24% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  26% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  28% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  30% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  32% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  34% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  36% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  38% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  40% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  42% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  44% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  46% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  48% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  50% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  52% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  54% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  56% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  58% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  60% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  62% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  64% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  66% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  68% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  70% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  72% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  74% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  76% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  78% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  80% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  82% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  84% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  86% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  88% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  90% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  92% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  94% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  96% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  98% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); }
}

@keyframes shake-little {
  2% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  4% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  6% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  8% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  10% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  12% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  14% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  16% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  18% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  20% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  22% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  24% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  26% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  28% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  30% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  32% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  34% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  36% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  38% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  40% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  42% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  44% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  46% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  48% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  50% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  52% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  54% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  56% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  58% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  60% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  62% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  64% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  66% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  68% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  70% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  72% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  74% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  76% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  78% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  80% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  82% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  84% {
    transform: translate(1px, 1px) rotate(0.5deg); }
  86% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  88% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  90% {
    transform: translate(1px, 0px) rotate(0.5deg); }
  92% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  94% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  96% {
    transform: translate(0px, 0px) rotate(0.5deg); }
  98% {
    transform: translate(0px, 1px) rotate(0.5deg); }
  0%, 100% {
    transform: translate(0, 0) rotate(0); }
  }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
}
