/*---------------*\
  $BREADCRUMB
\*---------------*/
/**
 * breadcrumb...
 */



.main-content {

  .breadcrumb {
    background-color: transparent;
    color: $color-grey-3;
    font-size: 0.75rem;
    line-height: 1;
    margin: 0 0 15px;
    padding: 10px 0 0;

    .fa {
      font-size: 1rem;
    }

    a,
    a:focus,
    a:hover {
      background: none !important;
      border: 0 none !important;
      color: $color-grey-3 !important;
      padding: 0 !important;
      text-decoration: none !important;
    }

    a:hover {
      color: $color-yellow !important;
    }
  }
}
