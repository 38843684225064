/*---------------*\
  $PRODUCT
\*---------------*/
/**
 * DEFAULT........
 * OWL CAROUSEL...
 * PRICE..........
 * TRAILER........
 * SHARE..........
 */



body.page-template-product {



  /*---------------*\
    $DEFAULT
  \*---------------*/
  .main-content {

    ul {
      margin: 0 0 30px;
      padding: 0;

      > li {

        > ul {
          margin-bottom: 10px;
          padding-left: 10px;

          > li {
            background: url("../img/icon/list-style-arrow.png") no-repeat 0 5px;
            padding-left: 17px;
          }
        }
      }
    }

    dl {
      margin: 0;
    }

    .btn-yellow,
    .btn-yellow:focus,
    .btn-yellow:hover {
      font-size: 0.9375rem;
      font-weight: 700;
      line-height: 33px;

      svg {
        display: inline-block;
        height: 33px;
        margin-left: 5px;
        max-width: 100%;
        vertical-align: middle;
        width: auto;

        polygon,
        path {
          fill: $color-yellow !important;
        }
      }
    }

    .btn-yellow:hover {

      svg {
        height: 33px;
        max-width: 100%;
        width: auto;

        polygon,
        path {
          fill: $color-grey-1 !important;
        }
      }
    }



    /*---------------*\
      $OWL CAROUSEL
    \*---------------*/
    .owl-container {
      background-color: $color-white;

      .owl-carousel {

        &#slideshow {

          .owl-item {

            .item {
              padding: 0;
            }
          }
        }

        .owl-controls {

          .owl-buttons {

            .owl-prev,
            .owl-next {
              background-color: transparent;
            }

            .owl-prev {
              background-image: url("../img/icon/arrow-left-grey.png");
            }

            .owl-next {
              background-image: url("../img/icon/arrow-right-grey.png");
            }
          }
        }

      }
    }



    /*---------------*\
      $PRICE
    \*---------------*/
    .price {
      margin-bottom: 30px;
      text-align: center;

      span {
        font-family: $font-2;
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 42px;

        > span {
          font-size: 1.5rem;
        }
      }
    }



    /*---------------*\
      $TRAILER
    \*---------------*/
    .trailer {
      margin-bottom: 30px;
    }



    /*---------------*\
      $SHARE
    \*---------------*/
    .share {
      margin-top: 30px;
    }



  }


  
}