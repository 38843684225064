/*---------------*\
  $DEFAULT
\*---------------*/
/**
 * DEFAULT............
 * ALERT COOKIES......
 * LOADING............
  * MAIN CONTENT......
  *-- DEFAULT.........
  *-- ILLUSTRATION....
  *-- MORE....
  *-- ORIGINE.........
  *-- TAGS............
  *-- ADDTHIS.........
  *-- AD..............
  *-- ITEMS...........
  *-- INSERTS.........
  *-- ACCORDION.......
  *-- POST META.......
  *-- VIDEOS..........
  *-- QUOTES..........
  *-- SIMILAR ITEMS...
  *-- BROADCASTS......
  *-- GIFTS...........
  *-- COMMENTS........
 */



html,
body {
	font-size: 100%;
	height: 100%;
}

body {
	//background: url('../img/bkg/body.jpg') 50% 0 $color-yellow;
	background-color: $color-yellow;
	color: $color-grey-1;
	font-family: $font-1;
	font-size: 16px;
	font-weight: 400;
}

img {
	vertical-align: baseline;
}

.img-responsive {
	display: inline-block;
}

@media only screen and (max-width: 479px) {
	.alignright,
	.alignleft {
		display: block;
		float: none;
		margin-right: auto;
		margin-left: auto;
	}
}

@media only screen and (max-width: 767px) {
	.table-responsive {
		border: none !important;
	}
}

@media only screen and (max-width: 1199px) {
	.container {
		margin-right: auto;
		margin-left: auto;
		padding-right: 15px;
		padding-left: 15px;
		width: auto;
	}
}

.main-container {
	background-color: $color-white;
}

a,
a:focus,
a:hover {
	color: $color-grey-1;
	text-decoration: underline;
}

a:hover {
	color: $color-yellow;
}

.color-white {
	color: $color-white !important;
}

.color-black {
	color: $color-black !important;
}

.color-grey {
	color: $color-grey-3 !important;
}

.color-dark-grey {
	color: $color-grey-1 !important;
}

.color-light-grey {
	color: $color-grey-2 !important;
}

.color-yellow {
	color: $color-yellow !important;
}

.color-red {
	color: $color-red !important;
}

.spoiler {
	color: transparent;
	@include transition(all, ease, .1s);

	a {
		background: inherit !important;
		color: inherit !important;
	}

	&:focus, &:hover, &:active {
		color: inherit;

		a:not(.btn) {
			background-image: -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow)) !important;
			background-image: linear-gradient(180deg, $color-yellow, $color-yellow) !important;
			background-position: 0 1rem !important;
			background-repeat: no-repeat !important;
			background-size: 100% 100% !important;
			color: $color-grey-1 !important;

			&:focus, &:hover, &:active {
				background-position: 0 0 !important;
			}
		}
	}
}

.opacity-hidden {
	@include opacity(0, 0);
}

.opacity-visible {
	@include opacity(1, 100);
}

.main-banner {
	background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#f2f2f2));
	background: linear-gradient(#f9f9f9, #f2f2f2);
	overflow: hidden;
	padding: 30px;
}

.main-content {
	padding: 0 15px;

	@media only screen and (min-width: 992px) {
		float: left;
		padding: 0 30px;
		width: 66.66667%;
	}
}

.alert-cookies {
	bottom: 0;
	font-size: .75rem;
	left: 0;
	line-height: 1.42857143;
	margin: 0;
	position: fixed;
	text-align: left;
	width: 100%;
	z-index: 10;
	@include border-radius(0);

	&::before {
		@media only screen and (min-width: 768px) {
			background-image: url("../img/bkg/cookies.png");
			bottom: 0;
			content: "";
			display: block;
			height: 153px;
			left: 0;
			position: absolute;
			width: 150px;
			-webkit-animation: animSlideUp 3s ease;
			        animation: animSlideUp 3s ease;
		}
	}

	.close {
		position: absolute;
		right: 5px;
		top: 5px;
	}

	.container {
		@media only screen and (min-width: 768px) {
			padding-left: 160px;
			padding-right: 160px;
		}
	}

	.media {
		margin: 0;
		width: 100%;

		.media-body,
		.media-left,
		.media-right {
			@media only screen and (max-width: 767px) {
				display: block;
			}
		}

		.media-body {
			@media only screen and (max-width: 767px) {
				margin-bottom: 10px;
				width: auto;
			}
		}

		.media-left,
		.media-right {
			@media only screen and (max-width: 767px) {
				padding: 0;
				text-align: center;
			}
		}
	}
}

.row-table {
	@media only screen and (min-width: 992px) {
		display: flex;
	}
}

.col-table-cell {
	@media only screen and (min-width: 992px) {
		//
	}
}

.progress-bar {
	color: $color-grey-1;
	white-space: nowrap;
}

.embed-responsive {
	margin-bottom: 30px;
}



/*---------------*\
 $MAIN CONTENT
\*---------------*/
.main-content {
	/*---------------*\
   $DEFAULT
 \*---------------*/
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font-2;
		font-weight: 500;
		margin: 0 0 15px;
	}

	h1 {
		font-size: 1.875rem;
	}

	h2 {
		font-size: 1.6875rem;
		font-weight: 700;
	}

	h3 {
		font-size: 1.5rem;
	}

	h4 {
		font-size: 1.3125rem;
	}

	h5 {
		font-size: 1.125rem;
	}

	h6 {
		font-size: 1rem;
	}

	.malcolm-france {
		font-family: "Malcolm France";
	}

	.neon-lights {
		font-family: "Neon Lights";
	}

	p {
		font-size: .9375rem;
		line-height: 22px;
		margin: 0 0 30px;
		text-align: justify;

		&.text-left {
			text-align: left !important;
		}

		&.text-center {
			text-align: center !important;
		}

		&.text-right {
			text-align: right !important;
		}

		a:not(.btn),
		a:not(.btn):focus,
		a:not(.btn):hover {
			background-image: -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow));
			background-image: linear-gradient(180deg, $color-yellow, $color-yellow);
			background-position: 0 1rem;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			color: $color-grey-1;
			text-decoration: none;
			@include transition(all, ease, .1s);
		}

		a:not(.btn):hover {
			background-position: 0 0;
		}
	}

	ul:not(.breadcrumb, .nav-tabs, .page-numbers) {
		list-style: none;
		margin: 0 0 30px;
		padding: 0 0 0 15px;

		li {
			background: url("../img/icon/list-style-arrow.png") no-repeat 0 5px;
			font-size: .9375rem;
			line-height: 22px;
			padding-left: 17px;

			a:not(.btn, .page-numbers),
			a:not(.btn, .page-numbers):focus,
			a:not(.btn, .page-numbers):hover {
				background-image: -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow));
				background-image: linear-gradient(180deg, $color-yellow, $color-yellow);
				background-position: 0 1rem;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				color: $color-grey-1;
				text-decoration: none;
				@include transition(all, ease, .1s);
			}

			a:not(.btn, .page-numbers):hover {
				background-position: 0 0;
			}

			ul {
				list-style: none;
				padding: 0;
			}
		}
	}

	dl {
		dt,
		dd {
			font-size: .9375rem;
			line-height: 22px;

			a:not(.btn),
			a:not(.btn):focus,
			a:not(.btn):hover {
				background-image: -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow));
				background-image: linear-gradient(180deg, $color-yellow, $color-yellow);
				background-position: 0 1rem;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				color: $color-grey-1;
				text-decoration: none;
				@include transition(all, ease, .1s);
			}

			a:not(.btn):hover {
				background-position: 0 0;
			}
		}
	}

	.well,
	blockquote,
	table {

		a:not(.btn),
		a:not(.btn):focus,
		a:not(.btn):hover {
			background-image: -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow));
			background-image: linear-gradient(180deg, $color-yellow, $color-yellow);
			background-position: 0 1rem;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			color: $color-grey-1;
			text-decoration: none;
			@include transition(all, ease, .1s);
		}

		a:not(.btn):hover {
			background-position: 0 0;
		}
	}

	.poll {

		ul {

			li {
				background: transparent;
				padding: 0;
			}
		}
	}

	.well {

		:last-child {
			margin-bottom: 0;
		}
	}

	blockquote {
		border: 0 none;
		margin: 0 0 30px;
		padding: 13px 15px 13px 35px;
		position: relative;

		&:before {
			color: $color-yellow;
			content: "“";
			font-family: "Arial", sans-serif;
			font-size: 6.25rem;
			font-style: normal;
			left: 0;
			line-height: 1;
			position: absolute;
			top: 0;
		}

		p {
			font-size: .9375rem;
			font-style: italic;
			line-height: 22px;
			text-align: left;
		}

		&.wide {
			padding: 26px 15px 26px 70px;

			&:before {
				font-size: 12.5rem;
			}

			p {
				color: $color-grey-3 ;
				font-family: $font-2;
				font-size: 1.875rem;
				font-weight: 500;
				line-height: 44px;
			}
		}
	}

	.post {

      table {
        margin: 0 0 30px;

        > thead {

          > tr {

            > th {
              color: $color-grey-1;
              font-size: 0.875rem;
              font-weight: 400;
              text-transform: uppercase;
            }
          }
        }

        > tbody {

          > tr {

            > td {
              font-size: 0.75rem;

              &:first-child {
                color: $color-grey-3;
                white-space: nowrap;
                width: 76px;
              }

              a,
              a:focus,
              a:hover {
                background-image:      -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow));
                background-image:      linear-gradient(180deg, $color-yellow, $color-yellow);
                background-position: 0 1em;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                color: $color-grey-1;
                text-decoration: none;
                @include transition(all, ease, .1s);
              }

              a:hover {
                background-position: 0 0;
              }
            }
          }
        }
      }
	}



	/*---------------*\
   $ILLUSTRATION
 \*---------------*/
	.illustration {
		margin-bottom: 30px;
		position: relative;
		text-align: center;

		img {
			display: block;
			margin: auto;
			width: auto;
		}

		.copyright {
			background-color: $color-black-25;
			bottom: 0;
			color: $color-grey-3;
			cursor: default;
			font-size: .75rem;
			line-height: 15px;
			padding: 1px 5px;
			position: absolute;
			right: 0;
		}
	}

	.legend {
		background-color: $color-dark-grey;
		color: $color-white;
		font-size: .75rem;
		margin-top: -30px;
		margin-bottom: 30px;
		padding: 5px 15px;
	}



	/*---------------*\
		$MORE
	\*---------------*/
    .more,
    .more:focus,
    .more:hover {
    	background: transparent !important;
		color: $color-white !important;
		display: block;
		margin-bottom: 30px;
		overflow: hidden;
		position: relative;
		text-decoration: none;

		.content {
	        align-content: center;
	        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .25)), to(rgba(0, 0, 0, .75)));
	        background: linear-gradient(rgba(0, 0, 0, .25), rgba(0, 0, 0, .75));
	        display: flex;
	        flex-direction: column;
	        height: 100%;
	        justify-content: start;
	        left: 0;
	        padding: 5px 15px;
	        position: absolute;
	        text-align: center;
	        top: 50%;
	        width: 100%;
	        z-index: 1;
	        @include transition(all, ease, .3s);

	        @media only screen and (max-width: 991px) {
				justify-content: center;
				top: 0;
	        }

	        .title {
				display: block;
				font-family: $font-2;
				font-size: 1.25rem;
				font-weight: 500;
				line-height: 1;
				margin-bottom: 15px;
				text-transform: uppercase;
				@include transition(all, ease, .3s);

				@media only screen and (min-width: 1200px) {
					font-size: 1.75rem;
				}
	        }

	        .desc {
				display: block;
				font-size: 1rem;
				line-height: 1;

				@media only screen and (min-width: 768px) and (max-width: 991px) {
					display: none;
				}

				@media only screen and (min-width: 992px) {
					@include opacity(0, 0);
					@include transition(all, ease, .3s);
				}
        	}
		}

		.img-container {
			display: block;
			overflow: hidden;
			padding: 0;
			position: relative;

	        .img {
				display: block;
				height: 0;
	        	padding-bottom: 56.25%;
				position: relative;
				@include transition(all, ease, .3s);

				img {
					height: auto;
					left: 50%;
					position: absolute;
					top: 50%;
					@include translate(-50%, -50%);
					width: 100%;
				}
			}
		}

	    &:hover {

			.content {
		        justify-content: center;
		        top: 0;

		        .desc {
		        	@include opacity(1, 100);
		        }
	    	}

		    .img-container {

		        .img {
		        	-webkit-transform: scale(1.1);
		        	transform: scale(1.1);
		        }
			}
		}
	}



	/*---------------*\
   $ORIGINE
 \*---------------*/
	.origine {
		margin-bottom: 30px;

		ul {
			list-style: none;
			margin: 0 0 10px;
			padding: 0;

			li {
				background: none;
				display: inline;
				font-size: .875rem;
				font-weight: 700;
				margin-bottom: 5px;
				padding: 0;
				text-transform: uppercase;

				a,
				a:focus,
				a:hover {
					background: $color-off-white;
					border: 0 none;
					color: $color-grey-1;
					display: inline-block;
					font-weight: 400;
					padding: 1px 4px;
					text-decoration: none;
					text-transform: none;
					@include transition(all, ease, .3s);
				}

				a:hover {
					background: $color-dark-grey;
					color: $color-yellow;
				}
			}
		}
	}



	/*---------------*\
   $TAGS
 \*---------------*/
	.tags {
		font-size: .875rem;
		font-weight: 700;
		margin-bottom: 30px;
		text-transform: uppercase;

		 > a,
		 > a:focus,
		 > a:hover {
			background-color: $color-off-white;
			color: $color-grey-1;
			display: inline;
			font-weight: 400;
			margin-bottom: 5px;
			padding: 1px 4px;
			text-decoration: none;
			text-transform: none;
			@include transition(all, ease, .3s);
		}

		 > a:hover {
			background-color: $color-dark-grey;
			color: $color-yellow;
		}
	}



	/*---------------*\
   $ADDTHIS
 \*---------------*/
	.share {
		margin: 0 0 15px;

		@media only screen and (min-width: 1200px) {
			padding-left: 30px;
		}

		.title {
			border-right: 2px solid $color-grey-1;
			display: inline-block;
			font-family: $font-1;
			font-size: 1.25rem;
			font-weight: 200;
			height: 32px;
			line-height: 32px;
			margin: 0 9px 0 0;
			padding-right: 15px;
			text-transform: uppercase;
			vertical-align: top;

			@media only screen and (max-width: 767px) {
				font-size: .8125rem;
				margin: 0;
				padding-right: 5px;
			}
		}

		.addthis {
			display: inline-block;
			vertical-align: top;

			a,
			a:focus,
			a:hover {
				@include transition(all, ease, .3s);
			}

			a:hover {
				@include opacity(.5, 50);
			}
		}
	}



	/*---------------*\
   $AD
 \*---------------*/
	.ad {
		margin-bottom: 30px;
		max-width: 100%;
		overflow: hidden;
	}



	/*---------------*\
   $ITEMS
 \*---------------*/
	.items {
		article {
			@media only screen and (max-width: 767px) {
				border-bottom: 1px solid $color-off-white;
				margin: 0 0 30px;
				padding-bottom: 23px;
			}

			@media only screen and (min-width: 768px) {
				margin: 0 -15px 15px;
			}

			a,
			a:focus,
			a:hover {
				color: $color-grey-1;
				display: block;
				text-decoration: none;
				@include transition(all, ease, .3s);

				@media only screen and (min-width: 768px) {
					padding: 15px;
				}

				img {
					display: block;
					width: 100%;
					@include transition(all, ease, .3s);
				}
			}

			a:hover {
				background: -webkit-gradient(linear, left top, right top, from(#e8e8e8), to(#d8d9d9));
				background: linear-gradient(90deg, #e8e8e8, #d8d9d9);

				img {
					-webkit-transform: scale(1.1);
					        transform: scale(1.1);
				}
			}

			.img {
				display: block;
				margin-bottom: 15px;
				overflow: hidden;
				position: relative;

				@media only screen and (min-width: 768px) {
					float: left;
					margin: 0 15px 0 0;
					width: 33.333%;
				}

				i {
					color: $color-white;
					font-size: 4.375rem;
					left: 50%;
					position: absolute;
					top: 50%;
					z-index: 1;
					@include opacity(.5, 50);
					@include translate(-50%, -50%);
				}
			}

			.post-meta {
				color: $color-grey-3;
				display: block;
				font-size: .6875rem;
				margin-bottom: 0;
				text-transform: uppercase;

				.date,
				.hour {
					display: inline-block;
					margin-bottom: 0;
				}
			}

			.post-title {
				color: $color-black;
				display: block;
				font-family: $font-2;
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 1.575rem;
				margin: 5px 0 10px;
			}

			.post-desc {
				display: block;
				font-size: .9375rem;
				line-height: 22px;
				text-align: justify;

				p {
					margin: 0;
				}
			}
		}
	}



	/*---------------*\
   $INSERTS
 \*---------------*/
	.inserts {
		a,
		a:focus,
		a:hover {
			background-color: $color-black;
			color: $color-white;
			display: block;
			height: 0;
			margin-bottom: 30px;
			overflow: hidden;
			padding-bottom: 56.25%;
			position: relative;

			.content {
				align-content: center;
				background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .25)), to(rgba(0, 0, 0, .75)));
				background: linear-gradient(rgba(0, 0, 0, .25), rgba(0, 0, 0, .75));
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: start;
				left: 0;
				padding: 5px 15px;
				position: absolute;
				text-align: center;
				top: 50%;
				width: 100%;
				z-index: 1;
				@include transition(all, ease, .3s);

				@media only screen and (max-width: 991px) {
					justify-content: center;
					top: 0;
				}

				.category {
					color: $color-white;
					display: block;
					font-family: $font-2;
					font-size: .875rem;
					font-weight: 500;
					line-height: 1;
					margin: 5px 0 10px;
					text-transform: uppercase;

					@media only screen and (min-width: 1200px) {
						font-size: 1rem;
					}

					span {
						background-color: $color-yellow;
						display: inline;
						padding: 0 8px;
						vertical-align: baseline;
					}
				}

				.number {
					color: $color-yellow;
					display: block;
					font-size: .5rem;
					line-height: 1;
					margin: 0 auto;
					padding: 3px 5px 0;
					text-align: center;
					text-transform: uppercase;
					vertical-align: middle;
					width: 45px;

					span {
						display: block;
						font-size: 1.125rem;
					}
				}

				.title {
					display: block;
					font-family: $font-2;
					font-size: 1.75rem;
					font-weight: 700;
					line-height: 1;
					margin-bottom: 15px;
					text-transform: uppercase;
					@include transition(all, ease, .3s);

					@media only screen and (max-width: 1199px) {
						font-size: 1.25rem;
					}
				}

				.desc {
					display: block;
					font-size: .9375rem;
					line-height: 1;
					@include opacity(0, 0);
					@include transition(all, ease, .3s);

					@media only screen and (max-width: 991px) {
						@include opacity(1, 100);
					}
				}
			}

			img {
				display: block;
				@include transition(all, ease, .3s);
			}
		}

		a:hover {
			.content {
				justify-content: center;
				top: 0;

				.desc {
					@include opacity(1, 100);
				}
			}

			img {
				-webkit-transform: scale(1.1);
				        transform: scale(1.1);
			}
		}
	}



	/*---------------*\
    $ACCORDION
  \*---------------*/
	.accordion {
		margin-bottom: 30px;

		dl {
			dt {
				font-weight: 700;
				margin-top: 15px;

				a,
				a:focus,
				a:hover {
					background: transparent;
					color: $color-grey-1;
					text-decoration: none;
				}
			}

			dd {
				padding: 5px;
			}
		}

		.label {
			background-color: $color-off-white;
			color: $color-grey-1;
			font-size: .875rem;
			font-weight: 400;
			height: 18px;
			line-height: 16px;
			padding: 1px 5px 1px 26px;
			position: relative;
			text-transform: uppercase;
			white-space: normal;
			-webkit-border-radius: 0;
			   -moz-border-radius: 0;
			        border-radius: 0;

			i {
				font-size: 1rem;
				height: 16px;
				left: 5px;
				position: absolute;
				top: 1px;
				width: 16px;
				@include transition(all, ease, .3s);
				&::before {
					content: "\f146";
				}
			}
		}

		.collapsed {

			.label {

				i {

					&::before {
						content: "\f0fe";
					}
				}
			}
		}
	}



	/*---------------*\
   $POST META
 \*---------------*/
	.post-meta {
		color: $color-grey-3;
		font-family: $font-1;
		font-size: .6875rem;
		font-weight: 200;
		margin-bottom: 15px;
		text-transform: uppercase;

		a,
		a:focus,
		a:hover {
			color: $color-grey-3;
			text-decoration: none;
		}

		a:hover {
			color: $color-yellow;
		}

		.author,
		.edit,
		.date,
		.hour,
		.comments {
			display: inline-block;
			margin-bottom: 10px;
			vertical-align: middle;

			 + span {
				margin-left: 10px;
			}

			&.author {
				a,
				a:focus,
				a:hover {
					font-weight: 700;
				}
			}
		}
	}



	/*---------------*\
   $VIDEOS
 \*---------------*/
	.videos {
		a,
		a:focus,
		a:hover {
			background-color: $color-black;
			color: $color-white;
			display: inline-block;
			margin-bottom: 30px;
			overflow: hidden;
			position: relative;

			i {
				font-size: 4.375rem;
				left: 50%;
				margin: -35px 0 0 -35px;
				position: absolute;
				top: 50%;
				z-index: 1;
				@include opacity(.5, 50);
			}

			span {
				font-size: 1rem;
				left: 0;
				position: absolute;
				text-align: center;
				top: 50%;
				width: 100%;
				z-index: 2;
				@include translate(0, -50%);

				@media only screen and (min-width: 992px) {
					display: none;
				}
			}

			img {
				display: block;
				width: 100%;
				@include transition(all, ease, .3s);

				@media only screen and (max-width: 991px) {
					@include opacity(.5, 50);
				}
			}
		}

		a:hover {
			span {
				display: block;
			}

			img {
				@include opacity(.5, 50);
				-webkit-transform: scale(1.1);
				        transform: scale(1.1);
			}
		}
	}



	/*---------------*\
   $QUOTES
 \*---------------*/
	.quotes {
		margin-bottom: 30px;

      > ul {

        > li {
          background: $color-off-white;
          border-radius: 3px;
          margin: 0 0 30px;
          padding: 15px 15px 15px 35px;
          position: relative;
          @include transition(all, ease, .3s);

          &::before {
            background-image: url("../img/icon/list-style-arrow.png");
            content: "";
            height: 11px;
            left: 15px;
            position: absolute;
            top: 20px;
            width: 9px;
          }

          &:hover {
            background: -webkit-linear-gradient(90deg, #e8e8e8, #d8d9d9);
            background:    -moz-linear-gradient(90deg, #e8e8e8, #d8d9d9);
            background:      -o-linear-gradient(90deg, #e8e8e8, #d8d9d9);
            background:         linear-gradient(90deg, #e8e8e8, #d8d9d9);
            cursor: pointer;
          }

          > ul {
            margin: 0;

            li {
              background: none;
            }
          }
        }
      }

		blockquote {
			 > ul {
				 > li {
					color: $color-grey-3;
					font-family: $font-2;
					font-size: 1.25rem;
					font-weight: 500;
					line-height: 30px;

					@media only screen and (min-width: 992px) {
						font-size: 1.875rem;
						line-height: 44px;
					}
				}
			}
		}
	}



	/*---------------*\
   $SIMILAR ITEMS
 \*---------------*/
	.similar-items {
		font-size: .875rem;
		font-weight: 700;
		margin-bottom: 30px;
		text-transform: uppercase;

		 > a,
		 > a:focus,
		 > a:hover {
			background-color: $color-off-white;
			color: $color-grey-1;
			display: inline;
			font-weight: 400;
			margin-bottom: 5px;
			padding: 1px 4px;
			text-decoration: none;
			text-transform: none;
			@include transition(all, ease, .3s);
		}

		 > a:hover {
			background-color: $color-dark-grey;
			color: $color-yellow;
		}

		article {
			margin: 10px 0 5px;

			a,
			a:focus,
			a:hover {
				color: $color-grey-1;
				display: block;
				padding: 7px 15px;
				text-decoration: none;

				@media only screen and (min-width: 992px) {
					padding: 5px;
				}

				.img {
					display: block;
					float: left;
					margin-right: 15px;
					max-width: 90px;
					overflow: hidden;

					@media only screen and (min-width: 992px) {
						float: none;
						margin-right: 0;
						max-width: inherit;
					}

					img {
						display: block;
						@include transition(all, ease, .3s);
					}
				}
			}

			a:hover {
				background: -webkit-gradient(linear, left top, right top, from(#e8e8e8), to(#d8d9d9));
				background: linear-gradient(90deg, #e8e8e8, #d8d9d9);

				@media only screen and (min-width: 992px) {
					background: -webkit-gradient(linear, left top, left bottom, from(#e8e8e8), to(#d8d9d9));
					background: linear-gradient(#e8e8e8, #d8d9d9);
				}

				.img {
					img {
						-webkit-transform: scale(1.1) rotate(.01deg);
						        transform: scale(1.1) rotate(.01deg);
					}
				}
			}

			.post-title {
				display: block;
				font-family: $font-2;
				font-size: 1rem;
				font-weight: 700;
				line-height: 24px;
				margin: 0;
				padding: 10px 10px 5px;
				text-transform: none;
			}
		}
	}



	/*---------------*\
   $BROADCASTS
 \*---------------*/
	.broadcasts {
		.table {
			margin: 0 0 30px;

			 > thead {
				 > tr {
					 > th {
						color: $color-grey-1;
						font-size: .875rem;
						font-weight: 400;
						text-transform: uppercase;
					}
				}
			}

			 > tbody {
				 > tr {
					 > td {
						font-size: .75rem;

						// &:first-child {
						// 	color: $color-grey-3;
						// 	white-space: nowrap;
						// 	width: 200px;
						// }

						&.hour {
							font-weight: 700;
							text-align: center;
						}

						&.channel {
							text-align: center;
						}

						a,
						a:focus,
						a:hover {
							background-image: -webkit-gradient(linear, left top, left bottom, from($color-yellow), to($color-yellow));
							background-image: linear-gradient(180deg, $color-yellow, $color-yellow);
							background-position: 0 2rem;
							background-repeat: no-repeat;
							background-size: 100% 100%;
							border-bottom: 2px solid $color-yellow;
							color: $color-grey-1;
							padding-top: 2px;
							text-decoration: none;
							@include transition(all, ease, .1s);
						}

						a:hover {
							background-position: 0 0;
						}

						.alert {
							margin: 30px 0 0;
						}
					}
				}
			}
		}
	}



	/*---------------*\
   $GIFTS
 \*---------------*/
	.gifs {
		.embed-responsive {
			margin-bottom: 30px;

			img {
				left: 0;
				position: absolute;
				top: 50%;
				width: 100%;
				@include translate(0, -50%);
			}
		}
	}



	/*---------------*\
   $COMMENTS
 \*---------------*/
	#disqus_thread {
		> iframe:nth-child(1) {
			// display: none !important;
		}
	}
}
